import Groups from "./Groups";
import React, {useState} from "react";
import LayoutContainer from "./LayoutContainer";
import {Route, Switch, useRouteMatch} from "react-router-dom";
import {ThemeProvider} from "@material-ui/styles";

export default ({
                    children,
                    theme,
                    title = "",
                    logo = "",
                    logout_title = "",
                    name_builder = null,
                    basename,
                }) => {
    const [viewGroups, setViewGroups] = useState(true);

    return (
        <ThemeProvider theme={theme}>
            {!localStorage.getItem("group") ?
                <Groups
                    openDialog={viewGroups}
                    handleStatusDialogGroup={() => {
                        setViewGroups(false);
                    }}
                    disableBackdropClick
                    basename={basename}
                /> :
                <LayoutContainer
                    name_builder={name_builder}
                    logout_title={logout_title}
                    title={title}
                    logo={logo}
                    basename={basename}
                >
                    <Switch>
                        {React.Children.map(children, (child, index) => (
                            <Route key={index} path={`${child.props.route}`}>
                                {child}
                            </Route>
                        ))}
                    </Switch>
                </LayoutContainer>
            }
        </ThemeProvider>
    );
};
