import React, { useEffect, useState } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import LinearProgress from "@mui/material/LinearProgress";
import GroupsIcon from '@mui/icons-material/Groups';
import axios from 'axios';
import {
    Avatar,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import LogoutIcon from '@mui/icons-material/Logout';
import ErrorIcon from '@mui/icons-material/Error';

const styles = {
    icon: {
        backgroundColor: "#1DB46E",
    },
    logoutItem: {
        padding: "0px 16px",
    },
    logoutIcon: {
        backgroundColor: "#fafafa",
        color: "#1DB46E",
        marginRight: "16px",
    }
}

const SelectGroupDialog = (props) => {
    const [listItems, setListItems] = useState([]);
    const [isFetching, setIsFetching] = useState(false);

    const {
        source = "item-dialog",
        onSelect,
        onClose,
        open,
        url,
        label,
        disableBackdropClick = false,
        basename,
    } = props;

    const fetchItems = async () => {
        setIsFetching(true);
        try {
            axios.defaults.headers.common["Authorization"] = localStorage.getItem("auth_token");
            const res = await axios.get(url);
            if (res["data"]["groups"].length == 1 ){
                onSelectItem(res["data"]["groups"][0]);
            }
            setListItems(res["data"]["groups"]);
            setIsFetching(false);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchItems();
    }, []);

    const onSelectItem = (item) => onSelect(item);
    const onCloseSelect = () => onClose();
    ///TODO REVISAR POR QUE SE RENDERIZA VARIAS VECES!!!!

    return (
        <>
            {!isFetching ?
                <Dialog
                    maxWidth="xs"
                    onClose={onCloseSelect}
                    aria-labelledby="confirmation-dialog-title"
                    open={open}
                    disableBackdropClick={disableBackdropClick}
                >
                    <DialogTitle>{label}</DialogTitle>
                    <DialogContent dividers id={`confirm-dialog-content-${source}`}>
                        {!isFetching && listItems.length == 0 ?
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar sx={styles.icon}>
                                        <ErrorIcon/>
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={"Lo sentimos"} secondary={"Usted no está asociado a ningún grupo de " +
                                "trabajo, por favor contacte a su administrador"}></ListItemText>
                            </ListItem>
                            :
                            <List>
                                {listItems.map((option, index) => (
                                    <ListItem
                                        button
                                        key={option}
                                        key={index}
                                        onClick={() => onSelectItem(option)}
                                    >
                                        <ListItemAvatar>
                                            <Avatar sx={styles.icon}>
                                                <GroupsIcon/>
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText primary={option}/>
                                    </ListItem>
                                ))}
                            </List>
                        }
                        {!localStorage.getItem("group") ?
                            <>
                                <Divider/>
                                <List>
                                    <ListItem
                                        button
                                        key={listItems.length}
                                        onClick={() => {
                                            window.location.assign(basename + "/logout")
                                        }}
                                        sx={styles.logoutItem}
                                    >
                                        <Avatar sx={styles.logoutIcon}>
                                            <LogoutIcon/>
                                        </Avatar>
                                        <ListItemText primary={"Salir"}/>
                                    </ListItem>
                                </List>
                            </>
                            :
                            <></>
                        }
                    </DialogContent>
                    <div> {isFetching && <LinearProgress/>}</div>
                </Dialog>
                :
                <></>
            }
        </>
    );
};

export default SelectGroupDialog;
