import React, {useState} from "react";
import {Icon, MenuItem, Box} from "@mui/material";

const {REACT_APP_CONTEXT: contextApp} = process.env;

const styles = {
    menuItem: {
        "&:hover": {
            backgroundColor: "white",
            paddingLeft: "1rem",
            fontSize: 12,
            fontWeight: "bold",
            "& img": {
                boxShadow: "0px 2px 6px 0px rgba(0, 0, 0, 0.2)",
            },
        },
        "& img": {
            width: "2.25rem",
            borderRadius: "1.5rem",
        },
    },
    accessLabel: {
        paddingLeft: "1rem",
        fontSize: 11,
        fontWeight: "bold",
        color: "#202020",
        textOverflow: "ellipsis",
        overflow: "hidden",
    },
    button: {
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "#E8F7F0",
        },
        "&:active": {
            backgroundColor: "#E8F7F0",
        },
    },
    noButton: {
        cursor: "auto",
        pointerEvents: "none",
        "&:hover": {
            backgroundColor: "inherit",
        },
    }
}

export default ({
                    srcName,
                    label,
                    clickHandler = () => null,
                    button = true,
                }) => {
    const [iconColor, setIconColor] = useState("#1DB46E");

    return (
        <MenuItem
            onClick={clickHandler}
            sx={styles.menuItem}
            onMouseEnter={() => {
                if (button) setIconColor("#3ee095");
            }}
            onMouseLeave={() => {
                if (button) setIconColor("#1DB46E");
            }}
            sx={button ? styles.button : styles.noButton}
        >
            {srcName ? <Icon style={{color: iconColor}}>{srcName}</Icon> : <></>}
            <Box component="p" sx={styles.accessLabel}>{label}</Box>
        </MenuItem>
    );
};
