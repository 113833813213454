import React, {useState} from "react";
import NavBar from "./NavBar";
import AppDrawer from "./AppDrawer";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import {useLocation} from "react-router-dom";
import Link from "@mui/material/Link";

const LayoutContainer = ({
                             children,
                             title,
                             logo,
                             logout_title,
                             name_builder = null,
                             basename,
                         }) => {
    const [open, setOpen] = useState(false);
    const handleOpenDrawer = () => {
        setOpen(!open);
    };

    const pathnames = useLocation()
        .pathname.split("/")
        .filter((x) => x);

    const menuItems = children.props.children.filter(
        ({props}) => {
            const {skip_menu} = props.children.props
            return skip_menu == undefined || !skip_menu
        }
    );
    return (
        <>
            <AppDrawer
                menuItems={menuItems}
                open={open}
                setOpen={setOpen}
                setTittle={title}
            />
            <NavBar
                handleDrawer={handleOpenDrawer}
                title={title}
                logout_title={logout_title}
                menuItems={menuItems}
                logo={logo}
                name_builder={name_builder}
                basename={basename}
            />
            <div
                style={{
                    marginLeft: "20px",
                    marginTop: "10px",
                }}
            >
                <Breadcrumbs maxItems={2} aria-label="breadcrumb">
                    {pathnames
                        .filter((route) => {
                            const blocked_routes = ["edit", "details"];
                            return !blocked_routes.includes(route);
                        })
                        .map((route, index, arr) => {
                            const to = `/${pathnames.slice(0, index + 1).join("/")}`;
                            const last = index === arr.length - 1;
                            return last ? (
                                <Typography sx={{color: "#3E3E3E", height: "24px", fontWeight: "700"}}>
                                    {route}
                                </Typography>
                            ) : (
                                <Link color="inherit" underline="none" href={`${to}`}>
                                    {route}
                                </Link>
                            );
                        })}
                </Breadcrumbs>
            </div>
            {children}
        </>
    );
};

export default LayoutContainer;
