import React from "react";
import SelectGroupDialog from "./SelectGroupDialog";

export default ({
                    openDialog,
                    handleStatusDialogGroup,
                    disableBackdropClick = false,
                    basename,
                }) => {
    const onSelect = (group) => {
        localStorage.setItem("group", group);
        const massiveDetailPath = process.env.REACT_APP_MASSIVE_LOADS_DETAIL_HOST;
        const massiveListPath = "/solicitudes-masivas";
        // Si se cambia el grupo estando en el detalle de una solicitud masiva la navegación vuelve a la lista
        if (window.location.href.includes(massiveDetailPath)) {
            window.location.assign("/portal" + massiveListPath);
        } else {
            window.location.assign("");
        }
        handleStatusDialogGroup(!openDialog);
    };
    const onClose = () => {
        handleStatusDialogGroup(!openDialog);
    };

    return (
        <SelectGroupDialog
            disableBackdropClick={disableBackdropClick}
            open={openDialog}
            onClose={onClose}
            onSelect={onSelect}
            label="Grupos"
            url={"/me"}
            basename={basename}
        />
    );
};
