import React from "react";

import { Auth, AuthView } from "admin-alto";
import { createBrowserHistory } from "history";
import Layout from "./components/Layout/Layout.js";

import Grid from "@mui/material/Grid";
import GroupIcon from "@mui/icons-material/Group";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import AssignmentIcon from "@mui/icons-material/Assignment";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import AddTaskIcon from "@mui/icons-material/AddTask";

import CheckTheme from "./Themes";

import TemplateLogin from "./TemplateLogin.js";

// MicroFrontend
import MicroFrontend from "./components/MicroFrontend";

import { BrowserRouter as Router } from "react-router-dom";
import { Accordion, Container, List, ListItem } from "@mui/material";

const defaultHistory = createBrowserHistory();

const {
  REACT_APP_REPORT_MASSIVE_HOST: reportMassiveHost,
  REACT_APP_REPORTS_DETAIL_HOST: reportDetailHost,
  REACT_APP_SIMPLE_REPORTS_DETAIL_HOST: simpleReportDetailHost,
  REACT_APP_REPORT_LIST_HOST: reportListHost,
  REACT_APP_SIMPLE_REPORT_LIST_HOST: simpleReportListHost,
  REACT_APP_WELCOME_PAGE_HOST: welcomePageHost,
  REACT_APP_CHECKIDS_LIST_HOST: checkidsListHost,
  REACT_APP_PERSON_LIST_HOST: personListHost,
  REACT_APP_PERSON_DETAIL_HOST: PersonDetailHost,
  REACT_APP_CHECKID_DETAIL_HOST: checkidDetailHost,
  REACT_APP_MASSIVE_LOADS_LIST_HOST: massiveLoadsListHost,
  REACT_APP_MASSIVE_LOADS_DETAIL_HOST: massiveLoadsDetailHost,
  PUBLIC_URL: contextApp,
} = process.env;

function ReportMassive({ history }) {
  return (
    <Grid container>
      <Grid item container justifyContent="center" alignItems="center">
        <Grid item xs={12} sm={10} md={8}>
          <MicroFrontend
            history={history}
            host={reportMassiveHost}
            name="ReportMassiveRequest"
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

function Reports({ children }) {
  return (
    <Grid container>
      <Grid item container justifyContent="center" alignItems="center">
        <Grid item container xs={12} sm={10} md={8}>
          {children}
        </Grid>
      </Grid>
    </Grid>
  );
}

function ReportList({ history }) {
  return (
    <Grid container>
      <Grid item container justifyContent="center" alignItems="center">
        <Grid item xs={12} sm={10} md={8}>
          <MicroFrontend
            history={history}
            host={reportListHost}
            name="ReportList"
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

function WelcomePage({ history }) {
  return (
    <Grid container>
      <Grid item container justifyContent="center" alignItems="center">
        <Grid item xs={12} sm={10} md={10}>
          <MicroFrontend
            history={history}
            host={welcomePageHost}
            name="WelcomePage"
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

function SimpleReportList({ history }) {
  return (
    <Grid container>
      <Grid item container justifyContent="center" alignItems="center">
        <Grid item xs={12} sm={10} md={10}>
          <MicroFrontend
            history={history}
            host={simpleReportListHost}
            name="SimpleReportList"
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

function MassiveLoadsList({ history }) {
  return (
    <Grid container>
      <Grid item container justifyContent="center" alignItems="center">
        <Grid item xs={12} sm={10} md={10}>
          <MicroFrontend
            history={history}
            host={massiveLoadsListHost}
            name="MassiveLoadsList"
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

function MassiveLoadsDetail({ history }) {
  let uri = document.location.pathname;
  uri = uri.substring(uri.lastIndexOf("/") + 1);
  history.push(`${massiveLoadsDetailHost}/${uri}`);

  return (
    <Grid container>
      <Grid item container justifyContent="center" alignItems="center">
        <Grid item xs={12} sm={10} md={10}>
          <MicroFrontend
            history={history}
            host={massiveLoadsDetailHost}
            name="MassiveLoadsDetail"
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

function CheckidsList({ history }) {
  return (
    <Grid container>
      <Grid item container justifyContent="center" alignItems="center">
        <Grid item xs={12} sm={10} md={8}>
          <MicroFrontend
            history={history}
            host={checkidsListHost}
            name="ChekidsReportList"
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

function PersonList({ history }) {
  return (
    <Grid container>
      <Grid item container justifyContent="center" alignItems="center">
        <Grid item xs={12} sm={10} md={8}>
          <MicroFrontend
            history={history}
            host={personListHost}
            name="PersonList"
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

function Detalle({ history }) {
  let uri = document.location.pathname;
  uri = uri.substring(uri.lastIndexOf("/") + 1);
  history.push(`${reportDetailHost}/${uri}`);

  return (
    <Grid container>
      <Grid item container justifyContent="center" alignItems="center">
        <Grid item xs={12} sm={10} md={8}>
          <MicroFrontend
            history={history}
            host={reportDetailHost}
            name="ReportDetail"
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

function SimpleDetalle({ history }) {
  let uri = document.location.pathname;
  uri = uri.substring(uri.lastIndexOf("/") + 1);
  history.push(`${simpleReportDetailHost}/${uri}`);

  return (
    <Grid container>
      <Grid item container justifyContent="center" alignItems="center">
        <Grid item xs={12} sm={10} md={8}>
          <MicroFrontend
            history={history}
            host={simpleReportDetailHost}
            name="SimpleReportDetail"
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

function PersonaDetalle({ history }) {
  let uri = document.location.pathname;
  uri = uri.substring(uri.lastIndexOf("/") + 1);
  history.push(`${PersonDetailHost}/${uri}`);

  return (
    <Grid container>
      <Grid item container justifyContent="center" alignItems="center">
        <Grid item xs={12} sm={10} md={8}>
          <MicroFrontend
            history={history}
            host={PersonDetailHost}
            name="PersonDetail"
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

function CheckidDetalle({ history }) {
  let uri = document.location.pathname;
  uri = uri.substring(uri.lastIndexOf("/") + 1);
  history.push(`${checkidDetailHost}/${uri}`);

  return (
    <Grid container>
      <Grid item container justifyContent="center" alignItems="center">
        <Grid item xs={12} sm={10} md={8}>
          <MicroFrontend
            history={history}
            host={checkidDetailHost}
            name="CheckidDetail"
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default function App({ history = defaultHistory }) {
  return (
    <Auth
      recovery_url=""
      title=""
      theme={CheckTheme}
      sub_title="Reportes confiables, rápidos y a tu medida."
      home_url={`${contextApp}`}
      background={`${contextApp}/bg-checks.jpg`}
      logo={`${contextApp}/logo-checks.png`}
      basename={`${contextApp}`}
      providerClient={TemplateLogin()}
    >
      <AuthView path={`${contextApp}`}>
        <Router>
          <Layout
            title="Reportes Checks"
            logo={`${contextApp}/logo-checks.svg`}
            logout_title="Salir"
            theme={CheckTheme}
            name_builder={() => {
              const auth = localStorage.getItem("auth");
              if (auth) {
                let { email } = JSON.parse(auth);
                return email;
              }
            }}
            basename={`${contextApp}`}
          >
            <PersonaDetalle
              route="/detalles-persona/:personId"
              skip_menu={true}
              history={history}
            />

            <SimpleReportList
              name="Reportes Individuales"
              route="/reportes-simples"
              Icon={AssignmentIcon}
              history={history}
            />

            <MassiveLoadsList
              name="Solicitudes Masivas"
              route="/solicitudes-masivas"
              Icon={AssignmentIcon}
              history={history}
            />

            <MassiveLoadsDetail
              route="/detalle-solicitudes-masivas/:massiveLoadsId"
              skip_menu={true}
              history={history}
            />
            {/* 
                        <Container 
                            name = 'Mis reportes'
                            Icon={AssignmentTurnedInIcon}
                        >

                            <SimpleReportList
                                name='Reportes Simples'
                                route="/reportes-simples"
                                Icon={AssignmentIcon}
                                history={history}
                            />

                            <MassiveLoadsList
                                name='Solicitudes Masivas'
                                route="/solicitudes-masivas"
                                Icon={AssignmentIcon}
                                history={history}
                            />

                        </Container> */}

            <Detalle
              route="/detalles/:reportId"
              skip_menu={true}
              history={history}
            />

            <SimpleDetalle
              route="/detalles-simple/:reportId"
              skip_menu={true}
              history={history}
            />

            <ReportList route="/reportes" history={history} skip_menu />

            <SimpleReportList
              route="/reportes-simples"
              history={history}
              skip_menu
            />

            <MassiveLoadsList
              route="/solicitudes-masivas"
              history={history}
              skip_menu
            />

            <WelcomePage route="/" skip_menu={true} history={history} />

            <CheckidsList
              route="/reportes-checkid"
              history={history}
              skip_menu
            />

            <CheckidDetalle
              route="/detalles-checkid/:reportId"
              skip_menu={true}
              history={history}
            />
          </Layout>
        </Router>
      </AuthView>
    </Auth>
  );
}
