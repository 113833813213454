import axios from 'axios';

const HttpClient = axios.create();
const auth_token = localStorage.getItem("auth_token");

if(auth_token)
    HttpClient.defaults.headers.common['Authorization'] =  auth_token;

const TemplateLogin = () => {
  const { PUBLIC_URL: contextApp } = process.env;
  return {
    login : async (data) => {
      let _data = {
        email : data.email,
        password : data.password
      };

      const login = await HttpClient.post("/api/front/authenticate", _data)
      if (login.data.change_password) {
        window.location.assign(`${contextApp}/reset_password?email=${data.email}&token=${login.data.token}`)
        return {
          data : {
            token : "",
            error: "change_password"
          }
        };
      }else{

        return {
          data: {
            ...login.data,
            auth: {
              token: login?.data?.token,
              email: login.data.email
            }
          }
        }
      }
    },
    reset_password : async (data) => {
      return await HttpClient.post("/api/front/auth/simple/reset_password",data)
    },
    check_otp : async (data) => {
      let _data = {
        "token" : data.token,
        "code" : data.code
      }
      return await HttpClient.post("/api/front/auth/simple/confirm_reset_password",_data)
    },
    change_password : async (data) => {
      let _data = {
        "token" : data.token,
        "new_password" : data.password
      }
      return await HttpClient.post("/api/front/auth/simple/change_password",_data)
    },
    logout: async (localStorage) => {
      window.location.assign(`${contextApp}`)
    }

  }
}
export default TemplateLogin
