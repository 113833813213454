import React, {useEffect, useState} from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Menu from "@mui/material/Menu";
import {useHistory} from "react-router-dom";
import CustomMenuItem from "./CustomMenuItem";
import Divider from "@mui/material/Divider";
import GroupsIcon from '@mui/icons-material/Groups';
import LogoutIcon from '@mui/icons-material/Logout';
import Groups from "./Groups";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from "axios";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";

const styles = {
    imageLogin: {
        maxWidth: "174px",
        Height: "50px",
        marginTop: "5px",
    },
    margin: {
        margin: "8px",
        fontWeight: "900",
    },
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: "16px",
        padding: "12px"
    },
    title: {
        flexGrow: 1,
    },
    sectionDesktop: {
        display: {
            xs: 'none', md: 'flex'
        }
    },
    sectionMobile: {
        display: {
            xs: 'flex', md: 'none'
        }
    },
    menuPaper: {
        width: 220,
    },
}

export default ({
                    handleDrawer,
                    title,
                    menuItems,
                    logo,
                    logout_title,
                    name_builder = null,
                    basename,
                }) => {

    const {
        REACT_APP_PORTAL_CAR_HOST: portalHost
    } = process.env;


    const [dropdownItems, setdropdownItems] = useState([]);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
    const [dropdownMoreAnchorEl, setDropdownMoreAnchorEl] = useState(null);
    const [viewGroups, setViewGroups] = useState(false);
    const [groupChoices, setGroupChoices] = useState(false);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
    const isDropdownMenuOpen = Boolean(dropdownMoreAnchorEl);
    const history = useHistory();
    const _handleDrawer = () => {
        handleDrawer();
    };
    const handleMobileMenuOpen = (event) => setMobileMoreAnchorEl(event.currentTarget);
    const handleMobileMenuClose = () => setMobileMoreAnchorEl(null);
    const handleDropdownMenuOpen = (event,items) => {setDropdownMoreAnchorEl(event.currentTarget); setdropdownItems(items)};
    const handleDropdownMenuClose = () => setDropdownMoreAnchorEl(null);
    const handleLogout = () => {
        window.location.assign(basename + "/logout");
        handleMobileMenuClose();
        handleDropdownMenuClose()
    };
    const handleChangeGroup = () => {
        setViewGroups(true);
        handleMobileMenuClose();
        handleDropdownMenuClose()
    };

    const fetchGroups = async () => {
        try {
            const url = "/me"
            axios.defaults.headers.common["Authorization"] = localStorage.getItem("auth_token");
            const res = await axios.get(url);
            return res["data"]["groups"].length;
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchGroups().then(groups => {
            if (groups > 1) {
                setGroupChoices(true);
            }
        })
    }, []);

    const handleChangePortal= () => {
        window.location.href = portalHost
    }

    const renderMobileMenu = () => (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            getContentAnchorEl={null}
            anchorOrigin={{vertical: "bottom", horizontal: "right"}}
            id="primary-search-account-menu-mobile"
            keepMounted
            transformOrigin={{vertical: "top", horizontal: "right"}}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
            sx={styles.menuPaper}
        >
            <CustomMenuItem
                srcName=<AccountCircleIcon/>
                label={name_builder()}
                noHover
                button={false}
            />
            <Divider
                variant="middle"
                style={{marginTop: "1rem", marginBottom: "1rem"}}
            />
            {
                //Si el usuario tiene más de un grupo se habilita la opción de cambio de grupo
                groupChoices ?
                    <>
                        <CustomMenuItem
                            srcName=<GroupsIcon/>
                            label={localStorage.getItem("group")}
                            clickHandler = {handleChangeGroup}
                            fromApi
                        />
                    </> :
                    <>
                        <CustomMenuItem
                            srcName=<GroupsIcon/>
                            label={localStorage.getItem("group")}
                            noHover
                            button={false}
                        />
                    </>
            }
            <CustomMenuItem
                srcName=<LogoutIcon/>
                label={logout_title}
                isIcon
                clickHandler = {handleLogout}
            />
        </Menu>
    );

    const renderDropdown = () => (
        <Menu
            anchorEl={dropdownMoreAnchorEl}
            getContentAnchorEl={null}
            open={isDropdownMenuOpen}
            onClose={handleDropdownMenuClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            keepMounted
            sx={styles.menuPaper}
        >
            {dropdownItems.map(({ props }, index) => {
                return (
                    <CustomMenuItem
                        label={props.name}
                        clickHandler = {() => {
                            handleDropdownMenuClose();
                            if(history.location.pathname === props.route) {
                                history.push(`${props.route}`);
                                window.location.reload();
                            }
                            else {
                                history.push(`${props.route}`);
                            }
                        }}
                    />
                )
            })
            }
        </Menu>
    );
    
    
    return (
        <Box sx={styles.root}>
            <AppBar position="static" sx={{backgroundColor: "#1DB46E"}}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        sx={styles.menuButton}
                        color="inherit"
                        aria-label="menu"
                        onClick={_handleDrawer}
                    >
                        <MenuIcon/>
                    </IconButton>
                    {logo != "" ? (
                        <Typography variant="h6" sx={styles.title}>
                            <Link href="/portal" sx={{display: "contents"}} color="inherit" underline="none">
                                <Box component="img" src={logo} alt="imglogin" sx={styles.imageLogin} />
                            </Link>
                        </Typography>
                    ) : (
                        <Typography variant="h6" sx={styles.title}>
                            {title}
                        </Typography>
                    )}
                    <Box>
                        <Box sx={styles.sectionDesktop}>
                            {menuItems.length < 4 ? (
                                menuItems.map(({props}, index) => {
                                    let {name, route} = props.children.props;
                                    return (
                                        props.children.props.children ?
                                        <Button
                                            key={index}
                                            size="medium"
                                            color="inherit"
                                            sx={styles.margin}
                                            onClick={(event) => {handleDropdownMenuOpen(event,props.children.props.children)}}
                                        >
                                            {name}
                                            <ExpandMoreIcon />
                                        </Button>
                                    :
                                        <Button
                                            key={index}
                                            size="medium"
                                            color="inherit"
                                            sx={styles.margin}
                                            onClick={() => {
                                                    if(history.location.pathname === route) {
                                                        history.push(`${route}`);
                                                        window.location.reload();
                                                    }
                                                    else {
                                                        history.push(`${route}`);
                                                    }
                                                }
                                            }

                                        >
                                            {name}
                                        </Button>
                                    );
                                })
                            ) : (
                                <></>
                            )}
                            <Box onClick={handleMobileMenuOpen}>
                                <IconButton
                                    aria-label="cuenta del usuario actual"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    color="inherit"
                                    sx={{padding: "12px"}}
                                >
                                    <AccountCircleIcon/>
                                </IconButton>
                            </Box>
                        </Box>
                        <Box sx={styles.sectionMobile}>
                            <Box onClick={handleMobileMenuOpen}>
                                <IconButton
                                    aria-label="cuenta del usuario actual"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    color="inherit"
                                >
                                    <AccountCircleIcon/>
                                </IconButton>
                            </Box>
                        </Box>
                    </Box>
                </Toolbar>
            </AppBar>
            {renderMobileMenu()}
            {renderDropdown()}
            {viewGroups && (
                <Groups
                    openDialog={viewGroups}
                    handleStatusDialogGroup={(value) => {
                        setViewGroups(value);
                    }}
                />
            )}
        </Box>
    );
};
