import React, { useState } from "react";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import TuneIcon from '@mui/icons-material/Tune';
import { useHistory } from "react-router-dom";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function AppDrawer({ menuItems, open, setOpen }) {
    const [anchor] = useState("left");
    const history = useHistory();

    const handleCloseDrawer = () => setOpen(false)


    const list = (menuItems) => (
        <div role="presentation">
            <List>
                {menuItems.map(({ props }, index) => {
                    let { Icon, name, route, children } = props.children.props;

                    if (children){
                        return(
                            <Accordion >
                                <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                >
                                    <ListItemIcon>
                                        {Icon != null ? <Icon /> : <TuneIcon />}
                                    </ListItemIcon>
                                    <ListItemText primary={name} />
                                </AccordionSummary>
                                <AccordionDetails>
                                    <List>
                                        {children.map(({props}, secondaryIndex) => (
                                            <ListItem
                                                button
                                                key={index+"-"+secondaryIndex}
                                                onClick={() => {
                                                    if(history.location.pathname === props.route) {
                                                        history.push(`${props.route}`);
                                                        window.location.reload();
                                                    }
                                                    else {
                                                        history.push(`${props.route}`);
                                                    }
                                                    handleCloseDrawer();
                                                }}
                                            >
                                                <ListItemIcon>
                                                    {props.Icon != null ? <props.Icon /> : <TuneIcon />}
                                                </ListItemIcon>
                                                <ListItemText primary={props.name} />
                                            </ListItem>
                                        ))}
                                    </List>
                                </AccordionDetails>
                            </Accordion>
                        )
                    } 
                    else{
                        return (
                            <ListItem
                                button
                                key={index}
                                onClick={() => {
                                    if(history.location.pathname === route) {
                                        history.push(`${route}`);
                                        window.location.reload();
                                    }
                                    else {
                                        history.push(`${route}`);
                                    }
                                    handleCloseDrawer();
                                }}
                            >
                                <ListItemIcon>
                                    {Icon != null ? <Icon /> : <TuneIcon />}
                                </ListItemIcon>
                                <ListItemText primary={name} />
                            </ListItem>
                        );
                    }
                })}
            </List>
        </div>
    );

    return (
        <SwipeableDrawer
            anchor={anchor}
            open={open}
            onClose={() => {
                handleCloseDrawer();
            }}
        >
            {list(menuItems)}
        </SwipeableDrawer>
    );
}
