import createTheme from "@mui/material/styles/createTheme";

const CheckTheme = createTheme({
  components: {
    MuiTypography: {
      styleOverrides: {
        h4: {
          fontSize: '2rem',
          fontWeight: 600,
          marginTop: 20,
        },
        h5: {
          fontSize: '1.1rem',
          fontWeight: 500,
        },
        h6: {
          fontSize: '1rem',
          fontWeight: 400,
        },
      },
    },
  },
  palette: {
    primary: {
      light: '#2FD186',
      main: '#1DB46E',
      dark: '#158652',
      contrastText: '#ffffff',
    },
    secondary: {
      light: '#6E6E6E',
      main: '#3E3E3E',
      dark: '#2F2F2F',
      contrastText: '#ffffff',
    },
  },
});


export default CheckTheme;
